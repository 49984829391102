<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="issueHistoryDetail">
		<common-title :title="'详情'" />
		<div class="issueHistoryDetail-content">
			<div class="issueHistoryDetail-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
						<template slot-scope="scope">
							<span>{{scope.row.workingHours.workingHours}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityId" label="活动ID">
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
					</el-table-column>
					<el-table-column prop="activity" label="活动活动日期">
						<template slot-scope="scope">
							<span>{{dateFormat(scope.row.activity.starTime)}} 至 {{dateFormat(scope.row.activity.endTime)}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">来源</span>
						<el-input readonly="readonly" v-model="issueHistoryDetail.source" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">状态</span>
						<span class="common-table-text">{{issueHistoryDetail.status == 1?"审理中": issueHistoryDetail.status == 2?"审核不通过": issueHistoryDetail.status == 3?"开具成功":""}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">操作人</span>
						<el-input readonly="readonly" v-model="issueHistoryDetail.operator" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">操作时间</span>
						<el-input v-model="issueHistoryDetail.operatorTime" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">工时证明文件</span>
						<div class="common-table-text">
							<span>{{issueHistoryDetail.filename}}</span>
							<span @click="downLoadFile" class="common-table-btn" v-if="issueHistoryDetail.filename">下载</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import { detailHandleApply, detailManHourProve } from "@/api/electronic"
	export default {
		data() {
			return {
				tableData: [],
				issueHistoryDetail: {},
				detailId: "",
				id: "",
			};
		},
		created() {
			this.detailId = this.$route.query.detailId
			this.id = this.$route.query.id
			this.getHandleApplyDetail()
			this.getManHourProveDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			downLoadFile() {
				if(this.issueHistoryDetail.accessUrl) {
					// window.open(scope.row.fileUrl)
					let a = document.createElement('a')
					a.target = "_blank"
					a.href = this.issueHistoryDetail.accessUrl
					a.download = this.issueHistoryDetail.filename
					a.dispatchEvent(new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: window
					}))
				}
			},
			getManHourProveDetail() {
				detailManHourProve(this.id, {id: this.id}).then((res) => {
					if(res.code == 0) {
						this.issueHistoryDetail = res.data
					}
				})
			},
			getHandleApplyDetail() {
				detailHandleApply(this.detailId, {id: this.detailId}).then((res) => {
					if(res.code == 0) {
						this.tableData = res.data
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}
	.common-table-btn {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #1CABB9;
		line-height: 22px;
		cursor: pointer;
		margin-left: 10px;
	}
	.issueHistoryDetail {
		.issueHistoryDetail-content {
			padding: 0 20px 20px 20px;
			.common-table {
				margin-top: 20px;
			}
		}
	}
</style>
